<template  lang="">
    <!-- 会员提现审核 -->
    <div style="height:100%;background:#fff">
        <!-- 第二行 表格操作栏 -->
        <div class="selectionBar">
            <div>
                <label>场景：</label>
                <el-date-picker style="width:230px" v-model="select.create_time" type="daterange" range-separator="至" start-placeholder="提现时间" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
            </div>
        </div>
        <p style="height:10px;background:#F0F3FA"></p>
        <!-- 表格 -->
        <div class="tableBox" style="height:calc( 100% - 91px )">
            <el-table ref="multipleTable" :data="list" highlight-current-row stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList' height="calc( 100% - 100px )">
                <el-table-column prop="user.realname" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.card" label="会员号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.nickname" label="昵称" show-overflow-tooltip></el-table-column>
                <el-table-column label="头像" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.user && scope.row.user.headimgurl" style="width: 45px;height: 45px;border-radius: 4px;" :src="scope.row.user.headimgurl"  :preview-src-list="[scope.row.user.headimgurl]"></el-image>
                        <el-avatar v-else shape="square" :size="40" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="提现金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status_name" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:#7B84FF">{{scope.row.status_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="提现时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="handleAudit(scope.row,10)">通过</el-button>
                        <el-button type="danger" plain size="mini" @click="handleAudit(scope.row,11)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {auditListApi,auditSaveApi}  from '@/api/audit.js';
export default {
    props: {
        searchValue: String
    },
    data() {
        return {
            select: {},
            list: [], //列表

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function(){
            auditListApi({
                curr:this.curr,
                row:this.row,
                status:0,
                ...this.select
            }).then(response=>{
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        // 审核
        handleAudit(row,stateId) {
            if (stateId == 11) { 
                //拒绝时
                this.$prompt(' ', '审批拒绝', {
                    inputPlaceholder: '请输入不通过原因',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    inputPattern: /\S/,
                    inputErrorMessage: '不得为空'
                }).then(({
                    value
                }) => {
                    this.saveAudit(row.id,stateId,value);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            } else {
                this.$confirm('此操作将通过审核, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.saveAudit(row.id,stateId,'');
                })
            }
        },
        saveAudit: function(id,status,value){
            auditSaveApi({
                id:id,
                status:status,
                note:value
            }).then(()=>{
                this.$message({
                    type: status == 10 ? 'success' : 'error',
                    message: status == 10 ? '审核已通过！' : '审核未通过！'
                });
                this.getList();
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
